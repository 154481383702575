<template >
  <div id="busquedaInt">
    <div id="headerHome" class="col-sm-12 col-md-12 color-bg-1">
      <HeaderHome :name="user.fullname" />
    </div>

    <div class="blog-post-cont">
      <h2 class="titulo-result-search">RESULTADOS <span>DE TU BUSQUEDA "{{this.searchv}}".</span></h2>

      <div class="tab-titles">
        <!--BUTTON-->
        <div class="button-tab-t activated" id="tabmx" @click="actTab()">
          <img src="../assets/lupa-tab.png" alt="icon" class="icon-tab-t">
          <h5 class="title-tab">TODO</h5>
        </div>
        <!--BUTTON-->
        <div class="button-tab-t" id="tabmx1" @click="actTab1()">
          <img src="../assets/news-tab.png" alt="icon" class="icon-tab-t">
          <h5 class="title-tab">NOTICIAS</h5>
        </div>
        <!--BUTTON-->
        <div class="button-tab-t" id="tabmx2" @click="actTab2()">
          <img src="../assets/image-tab.png" alt="icon" class="icon-tab-t">
          <h5 class="title-tab">GALERIAS</h5>
        </div>
        <!--BUTTON-->
        <div class="button-tab-t" id="tabmx3" @click="actTab3()">
          <img src="../assets/videos-tab.png" alt="icon" class="icon-tab-t">
          <h5 class="title-tab">VIDEOS</h5>
        </div>
        <!--BUTTON-->
        <div class="button-tab-t" id="tabmx4" @click="actTab4()">
          <img src="../assets/tienda-tab.png" alt="icon" class="icon-tab-t">
          <h5 class="title-tab">TIENDA</h5>
        </div>
      </div>

      <p class="result-count">Resultados</p>

        <div id="todo-t"
           v-if="searchdata == 'No se encontraron coincidencias' &&
           searchdata2 == 'No se encontraron coincidencias' &&
           searchdata3 == 'No se encontraron coincidencias' &&
           searchdata4 == 'No se encontraron coincidencias'">
            <div class="en-construccion-cont">
          <div id="no-avalibleT" class="undisPro">
                <img src="../assets/tarjeta-rojiza.png" alt="tarjetiza" class="tarjetiza">
                <h3 class="titulo-en-constr"><span class="red">NO SE ENCONTRARON RESULTADOS.</span></h3>
            </div>
          </div>
        </div>
        <div id="todo-t" v-else>
          <div v-if="searchdata == 'No se encontraron coincidencias'"></div>
          <div v-else>
            <div class="result-search-cont" v-for="(sd,i) in searchdata" :key="i">
              <a :href="'/blog/titulo/' + sd.url">
                <img :src="url+'/get-blog-image/'+sd.image" alt="img" class="res-s-img">
              </a>
              <div class="col-res">
                <a href="/blog"><h5 class="type-result">NOTICIAS</h5></a>
                <a :href="'/blog/titulo/' + sd.url">
                <h5 class="res-title">{{sd.title}}</h5>
                </a>
                <p class="parrafo-result">{{splitHTML(sd.content)}}</p>
              </div>
            </div>
          </div>


          <div v-if="searchdata4 == 'No se encontraron coincidencias'"></div>
          <div v-else>
            <div class="result-search-cont" v-for="(sg,i) in searchdata4" :key="i">
              <a @click="showModalG(sg.imagenes)">
                <img :src="url+'/get-gallery-image/'+sg.image" alt="img" class="res-s-img">
              </a>
              <div class="col-res">
                <a href="/galeria"><h5 class="type-result">GALERIAS</h5></a>
                <a>
                <h5 class="res-title" @click="showModalG(sg.imagenes)">{{sg.title}}</h5>
                </a>
              </div>
            </div>
        </div>

        <div v-if="searchdata2 == 'No se encontraron coincidencias'"></div>
          <div v-else>
            <div class="result-search-cont" v-for="(sv,i) in searchdata2" :key="i">
              <a @click="showModalB(sv)">
                <img :src="url+'/get-video-image/'+sv.image" alt="img" class="res-s-img">
              </a>
              <div class="col-res">
                <a href="/videos"><h5 class="type-result">VIDEOS</h5></a>
                <h5  @click="showModalB(sv)" class="res-title">{{sv.title}}</h5>
              </div>
            </div>
          </div> 

        <div v-if="searchdata3 == 'No se encontraron coincidencias'"></div>
          <div v-else>
            <div class="result-search-cont" v-for="(st,i) in searchdata3" :key="i">
              <img :src="url+'/get-product-image/'+st.image" alt="img" class="res-s-img">
              <div class="col-res">
                <a href="/tienda"><h5 class="type-result">TIENDA</h5></a>
                <a :href="'/producto/' + st.url"><h5 class="res-title">{{st.name}}</h5></a>
            <div
              class="cat-genre"
              v-if="genreName(st.categorias) !== 'N/A'"
            >
              CATEGORIA:
              <a :href="genreWeb(st.categorias)">{{
                genreName(st.categorias)
              }}</a>
            </div>
            <div
              class="cat-genre"
              v-else-if="genreName(st.categorias) == 'N/A'"
            >
              CATEGORIA:
              <a :href="'/categoria/' + tolc(st.category_name)">{{
                st.category_name
              }}</a>
            </div>
            <div
              class="cat-genre"
              v-else-if="
                st.categorias == 'No se encontraron coincidencias'
              "
            >
              CATEGORIA:
              <a :href="'/categoria/' + tolc(st.category_name)">{{
                st.category_name
              }}</a>
            </div>
              </div>
            </div>  
          </div> 
          </div> 











        <div id="noticia-t" v-if="searchdata == 'No se encontraron coincidencias'">
          <div id="no-avalibleN" class="undisPro">
            <div class="en-construccion-cont">
                <img src="../assets/tarjeta-rojiza.png" alt="tarjetiza" class="tarjetiza">
                <h3 class="titulo-en-constr"><span class="red">NO SE ENCONTRARON RESULTADOS.</span></h3>
            </div>
          </div>
        </div>
        <div id="noticia-t" v-else>
          <div class="result-search-cont" v-for="(sd,i) in searchdata" :key="i">
            <a :href="'/blog/titulo/' + sd.url">
              <img :src="url+'/get-blog-image/'+sd.image" alt="img" class="res-s-img">
            </a>
            <div class="col-res">
              <a href="/blog"><h5 class="type-result">NOTICIAS</h5></a>
              <a :href="'/blog/titulo/' + sd.url">
              <h5 class="res-title">{{sd.title}}</h5>
              </a>
              <p class="parrafo-result">{{splitHTML(sd.content)}}</p>
            </div>
          </div>
        </div>

    <GalleryModal v-show="isModalVisibleG" @close="closeModalG">
      <template v-slot:header>
        GALERÍA
      </template>
      <template v-slot:body> 
        <div class="gallery-item" v-for="(imadata,i) in modalDataG" :key="i">
          <img class="gallery-frame" :src="url+'/get-imagesgallery-image/'+imadata.image" alt="image" @click="showModalGM(imadata)"/>
        </div> 
      </template>
    </GalleryModal>

    <GalleryImgModal v-show="isModalVisibleGM" @close="closeModalGM">
      <template v-slot:body> 
        <img class="gallery-frame-img" :src="url+'/get-imagesgallery-image/'+modalDataGM.image" alt="image"/>
        <div class="gallery-mosaic">
          <img v-for="(md,i) in modalDataG" :key="i"
           :src="url+'/get-imagesgallery-image/'+md.image" alt="img" class="rest-of-pics" @click="showModalGM(md)">
        </div>
      </template>
    </GalleryImgModal>

        <div id="galeria-t" v-if="searchdata4 == 'No se encontraron coincidencias'">
          <div id="no-avalibleG" class="undisPro">
            <div class="en-construccion-cont">
                <img src="../assets/tarjeta-rojiza.png" alt="tarjetiza" class="tarjetiza">
                <h3 class="titulo-en-constr"><span class="red">NO SE ENCONTRARON RESULTADOS.</span></h3>
            </div>
          </div>
        </div>
       <div id="galeria-t" v-else>
          <div class="result-search-cont" v-for="(sg,i) in searchdata4" :key="i">
            <a @click="showModalG(sg.imagenes)">
              <img :src="url+'/get-gallery-image/'+sg.image" alt="img" class="res-s-img">
            </a>
            <div class="col-res">
              <a href="/galeria"><h5 class="type-result">GALERIAS</h5></a>
              <a>
              <h5 class="res-title" @click="showModalG(sg.imagenes)">{{sg.title}}</h5>
              </a>
            </div>
          </div>
        </div>


      <VideoModalB v-show="isModalVisibleB" @close="closeModalB()">
        <template v-slot:header> {{ modalDataB.title }} </template>

        <template v-slot:body> <div v-html="modalDataB.url"></div> </template>
      </VideoModalB>

        <div id="video-t" v-if="searchdata2 == 'No se encontraron coincidencias'">
          <div id="no-avalibleV" class="undisPro">
            <div class="en-construccion-cont">
                <img src="../assets/tarjeta-rojiza.png" alt="tarjetiza" class="tarjetiza">
                <h3 class="titulo-en-constr"><span class="red">NO SE ENCONTRARON RESULTADOS.</span></h3>
            </div>
          </div>
        </div>
        <div id="video-t" v-else>
        <div class="result-search-cont" v-for="(sv,i) in searchdata2" :key="i">
          <a @click="showModalB(sv)">
            <img :src="url+'/get-video-image/'+sv.image" alt="img" class="res-s-img">
          </a>
          <div class="col-res">
            <a href="/videos"><h5 class="type-result">VIDEOS</h5></a>
            <h5  @click="showModalB(sv)" class="res-title">{{sv.title}}</h5>
          </div>
        </div>
        </div>

        <div id="tienda-t" v-if="searchdata3 == 'No se encontraron coincidencias'">
          <div id="no-avalibleT" class="undisPro">
            <div class="en-construccion-cont">
                <img src="../assets/tarjeta-rojiza.png" alt="tarjetiza" class="tarjetiza">
                <h3 class="titulo-en-constr"><span class="red">NO SE ENCONTRARON RESULTADOS.</span></h3>
            </div>
          </div>
        </div>
        <div id="tienda-t" v-else>
          <div class="result-search-cont" v-for="(st,i) in searchdata3" :key="i">
            <img :src="url+'/get-product-image/'+st.image" alt="img" class="res-s-img">
            <div class="col-res">
              <a href="/tienda"><h5 class="type-result">TIENDA</h5></a>
              <a :href="'/producto/' + st.url"><h5 class="res-title">{{st.name}}</h5></a>
            </div>
          </div>   
        </div>

    </div>

    <div id="footerSection">
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../components/footer";
import HeaderHome from "../components/HeaderHome";
import VueCoreVideoPlayer from 'vue-core-video-player';
import { mapState, mapActions, mapMutations } from "vuex";
import VideoModalB from "../components/VideoModalB.vue";
import GalleryModal from "../components/GalleryModal.vue";
import GalleryImgModal from "../components/GalleryImgModal.vue";

export default {
  components: {
    VueCoreVideoPlayer,
    HeaderHome,
    Footer,
    VideoModalB,
    GalleryModal,
    GalleryImgModal
  },
  data() {
    return {
      isModalVisibleB: false,
      modalDataB: [],
      modalDataVideo: [],
      dataTienda: '',
      isModalVisibleG: false,
      modalDataG: null,
      isModalVisibleGM: false,
      modalDataGM: null,
      searchv: this.$route.params.search.replaceAll('-','/'),
    }
  },
   created() {
     this.search1(),
     this.search2(),
     this.search3(),
     this.search4()
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
    searchdata(){
      let match = this.$store.getters["blog/data5"];
      let newarray;
      if(match.length == 0){
        match = 'No se encontraron coincidencias'
      }else{
        match
      /* newarray = match.sort(function(a, b){ return new Date(b.descrp).getTime() > new Date(a.descrp).getTime();}).reverse();*/
      }
      return match;
    },
    searchdata2(){
      let videoda = this.$store.getters["video/data5"];
      let newarray;
      if(videoda.length == 0){
        videoda = 'No se encontraron coincidencias'
      }else{
        videoda
      /* newarray = videoda.sort(function(a, b){ return new Date(b.datevideo).getTime() > new Date(a.datevideo).getTime();}).reverse();*/
      }
      return videoda;
    },
    searchdata3() {
      let pro = this.$store.getters["product/data3"];
      let prodata = []
      for (let i = 0; i < pro.length; i++) {
        if(pro[i].status == 'ACTIVO'){
          prodata.push(pro[i])
        }
      }
      if(prodata.length == 0){
        prodata = 'No se encontraron coincidencias'
      }else if(prodata > 0){
        prodata
      }
      return prodata;
    },
    searchdata4(){
      let galdata = this.$store.getters["gallery2/data2"];
      if(galdata.length == 0){
        galdata = 'No se encontraron coincidencias'
      }else if(galdata > 0){
        galdata
      }
      return galdata;
    },
  },
  methods:{
    ...mapActions('blog', ['search1']),
    ...mapActions('video', ['search2']),
    ...mapActions('product', ['search3']), 
    ...mapActions('gallery2', ['search4']), 

    showModalB(data) {
      this.isModalVisibleB = true;
      this.modalDataB = data;
    },
    closeModalB() {
      this.isModalVisibleB = false;
      this.modalDataB = null;
    },

    showModalG(data) {
      this.isModalVisibleG = true;
      this.modalDataG = data;
    },
    closeModalG() {
      this.isModalVisibleG = false;
      this.modalDataG = null;
    },

    showModalGM(data) {
      this.isModalVisibleGM = true;
      this.modalDataGM = data;
    },
    closeModalGM() {
      this.isModalVisibleGM = false;
      this.modalDataGM = null;
    },

    search1: async function () {
      let name = this.searchv
      let n = name.split('/')
      let result = await this.$store.dispatch("blog/search1", {
        option: "/buscar/", normalizedOpt: "blog", "search": n[0]
      });
      this.info = result;
      return result;
    },

    search2: async function () {
      let name = this.searchv
      let n = name.split('/')
      let result = await this.$store.dispatch("video/search2", {
        option: "/buscar/", normalizedOpt: "videos", "search": n[0]
      });
      this.info = result;
      return result;
    },

    search3: async function () {
      let name = this.searchv
      let n = name.split('/')
      let result = await this.$store.dispatch("product/search3", {
        option: "/buscar/", normalizedOpt: "tienda", "search": n[0]
      });
      this.info = result;
      return result;
    },

    search4: async function () {
      let name = this.searchv
      let n = name.split('/')
      let result = await this.$store.dispatch("gallery2/search4", {
        option: "/buscar/", normalizedOpt: "galeria", "search": n[0]
      });
      this.info = result;
      return result;
    },

    genreName(genre) {
      let name = "N/A";
      for (let i = 0; i < genre.length; i++) {
        if (genre[i].category_name == "MUJER") {
          name = "MUJER";
        } else if (genre[i].category_name == "HOMBRE") {
          name = "HOMBRE";
        } else if (genre[i].category_name == "NIÑO") {
          name = "NIÑO";
        }
      }
      return name;
    },

    genreWeb(genre) {
      let name = "#";
      for (let i = 0; i < genre.length; i++) {
        if (genre[i].category_name == "MUJER") {
          name = "/categoria/mujer";
        } else if (genre[i].category_name == "HOMBRE") {
          name = "/categoria/hombre";
        } else if (genre[i].category_name == "NIÑO") {
          name = "/categoria/niño";
        }
      }
      return name;
    },
    tolc(palabra){
      let word = palabra.replaceAll(' ','-')
      let newword = word
           .normalize('NFD')
           .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
           .normalize();
      return newword.toLowerCase();
    },



    splitHTML(texto){
      let regex = /(<([^>]+)>)/ig
      let textoS = texto.replace(regex, "");
      let maxLength = 150
      let trimmedString = textoS.substr(0, maxLength);
       trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
      return trimmedString + " ...";
    },

    filterTitle(title) {
      let result;
      if (title == null){
      result = 'cosa'
      }else{
        result = title.replaceAll(" ", "-");
      }
      return result;
    },

    filterTitle2(search) {
      let result;
      if (search == null){
      result = 'cosa'
      }else{
        result = search.replaceAll(" ", "-");
      }
      return result;
    },

    
    unactTab(){
      document.getElementById('tabmx').classList.add('activated');
      document.getElementById('tabmx1').classList.remove('activated');
      document.getElementById('tabmx2').classList.remove('activated');
      document.getElementById('tabmx3').classList.remove('activated');
      document.getElementById('tabmx4').classList.remove('activated');
    },

    actTab(){
      document.getElementById('tabmx').classList.add('activated');
      document.getElementById('tabmx1').classList.remove('activated');
      document.getElementById('tabmx2').classList.remove('activated');
      document.getElementById('tabmx3').classList.remove('activated');
      document.getElementById('tabmx4').classList.remove('activated');

      document.getElementById('todo-t').classList.remove('undisT');

      document.getElementById('noticia-t').classList.remove('undis');
      document.getElementById('video-t').classList.remove('undis');
      document.getElementById('tienda-t').classList.remove('undis');
      document.getElementById('galeria-t').classList.remove('undis');

      
      document.getElementById('no-avalibleT').classList.remove('undisPro');
      document.getElementById('no-avalibleN').classList.add('undisPro');
      document.getElementById('no-avalibleG').classList.add('undisPro');
      document.getElementById('no-avalibleV').classList.add('undisPro');
      document.getElementById('no-avalibleT').classList.add('undisPro');
    },
    actTab1(){
      document.getElementById('tabmx1').classList.add('activated');
      document.getElementById('tabmx').classList.remove('activated');
      document.getElementById('tabmx2').classList.remove('activated');
      document.getElementById('tabmx3').classList.remove('activated');
      document.getElementById('tabmx4').classList.remove('activated');

      document.getElementById('todo-t').classList.add('undisT');

      document.getElementById('noticia-t').classList.add('undis');
      document.getElementById('video-t').classList.remove('undis');
      document.getElementById('tienda-t').classList.remove('undis');
      document.getElementById('galeria-t').classList.remove('undis');

      document.getElementById('no-avalibleT').classList.remove('undisPro');
      document.getElementById('no-avalibleN').classList.add('undisPro');
      document.getElementById('no-avalibleG').classList.remove('undisPro');
      document.getElementById('no-avalibleV').classList.remove('undisPro');
      document.getElementById('no-avalibleT').classList.remove('undisPro');
    },
    actTab2(){
      document.getElementById('tabmx2').classList.add('activated');
      document.getElementById('tabmx3').classList.remove('activated');
      document.getElementById('tabmx4').classList.remove('activated');
      document.getElementById('tabmx').classList.remove('activated');
      document.getElementById('tabmx1').classList.remove('activated');

      document.getElementById('todo-t').classList.add('undisT');

      document.getElementById('noticia-t').classList.remove('undis');
      document.getElementById('video-t').classList.remove('undis');
      document.getElementById('tienda-t').classList.remove('undis');
      document.getElementById('galeria-t').classList.add('undis');

      document.getElementById('no-avalibleT').classList.remove('undisPro');
      document.getElementById('no-avalibleN').classList.remove('undisPro');
      document.getElementById('no-avalibleG').classList.add('undisPro');
      document.getElementById('no-avalibleV').classList.remove('undisPro');
      document.getElementById('no-avalibleT').classList.remove('undisPro');
    },
    actTab3(){
      document.getElementById('tabmx3').classList.add('activated');
      document.getElementById('tabmx4').classList.remove('activated');
      document.getElementById('tabmx').classList.remove('activated');
      document.getElementById('tabmx1').classList.remove('activated');
      document.getElementById('tabmx2').classList.remove('activated');

      document.getElementById('todo-t').classList.add('undisT');

      document.getElementById('noticia-t').classList.remove('undis');
      document.getElementById('video-t').classList.add('undis');
      document.getElementById('tienda-t').classList.remove('undis');
      document.getElementById('galeria-t').classList.remove('undis');

      document.getElementById('no-avalibleT').classList.remove('undisPro');
      document.getElementById('no-avalibleN').classList.remove('undisPro');
      document.getElementById('no-avalibleG').classList.remove('undisPro');
      document.getElementById('no-avalibleV').classList.add('undisPro');
      document.getElementById('no-avalibleT').classList.remove('undisPro');
    },
    actTab4(){
      document.getElementById('tabmx4').classList.add('activated');
      document.getElementById('tabmx').classList.remove('activated');
      document.getElementById('tabmx1').classList.remove('activated');
      document.getElementById('tabmx2').classList.remove('activated');
      document.getElementById('tabmx3').classList.remove('activated');

      document.getElementById('todo-t').classList.add('undisT');

      document.getElementById('noticia-t').classList.remove('undis');
      document.getElementById('video-t').classList.remove('undis');
      document.getElementById('tienda-t').classList.add('undis');
      document.getElementById('galeria-t').classList.remove('undis');

      document.getElementById('no-avalibleT').classList.remove('undisPro');
      document.getElementById('no-avalibleN').classList.remove('undisPro');
      document.getElementById('no-avalibleG').classList.remove('undisPro');
      document.getElementById('no-avalibleV').classList.remove('undisPro');
      document.getElementById('no-avalibleT').classList.add('undisPro');
    },



  }
};
</script>

<style scoped>
.blog-post-cont {
    padding: 7.5vw;
}h2.titulo-result-search {
    font-size: 2.344vw;
    line-height: 2.708vw;
    color: #B70D1D;
    font-family: 'Rajdhani';
    margin-bottom: 4.531vw;
}h2.titulo-result-search span {
    font-family: 'Rajdhani';
    color: #01203a;
}.tab-titles {
    display: flex;
    width: 100%;
    border-top: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
    margin-bottom: 1.875vw;
}.button-tab-t {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 11.302vw;
    height: 5.729vw;
    cursor: pointer;
    transition: .5s;
}.button-tab-t:hover {
  background: rgb(158, 158, 158);
}.icon-tab-t {
    width: 1.146vw;
    margin-right: 0.977vw;
}h5.title-tab {
    font-size: 1.042vw;
    line-height: 1.198vw;
    color: #032A4B;
    font-family: 'Rajdhani';
    margin-bottom: 0;
}.activated {
    background: #b60d1d;
}.activated:hover {
    background: #6f0711;
}.activated .icon-tab-t {
    filter: brightness(50);
}.activated h5.title-tab {
    color: white;
}p.result-count {
    font-size: 1.146vw;
    font-family: 'Rajdhani';
    color: gray;
}img.res-s-img {
    width: 9.479vw;
    height: 6.927vw;
    object-fit: cover;
    object-position: top;
    background: #002340;
}h5.type-result {
    font-size: 0.729vw;
    line-height: 0.833vw;
    color: #B70D1D;
    font-family: 'Rajdhani';
}.result-search-cont {
    display: flex;
    align-items: center;
    padding: 1.719vw 0;
    border-bottom: 1px solid gray;
    background-image: url('../assets/flechared.png');
    background-size: 5.573vw;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: right;
}.col-res {
    margin-left: 2.344vw;
}h5.res-title {
    font-size: 1.302vw;
    line-height: 1.510vw;
    text-transform: uppercase;
    color: #032A4B;
    font-family: 'Rajdhani';
    cursor: pointer;
    transition: .25s;
}h5.res-title:hover {
    text-decoration: underline;
}p.parrafo-result {
    width: 35.938vw;
    color: gray;
    font-size: 0.833vw;
    font-family: 'Rajdhani';
    cursor: pointer;
}
.undis{
  display:block!important;
} 


.undisT{
  display:none!important;
}

#noticia-t,#video-t,#galeria-t,#tienda-t{
  display:none;
}

.undisPro{
  display:flex!important;align-items: center;
}
.dis{
  display:flex!important;
}
.disPro{
  display:flex!important;
}.cat-genre {
    margin: 0;
}


@media (max-width: 768px) {
  .blog-post-cont{
      padding: 40vw 0;
  }h2.titulo-result-search {
    font-size: 5.314vw;
    line-height: 5.314vw;
    padding: 0 6.763vw;
    margin-bottom: 10.531vw;
  }.button-tab-t {
    width: 20%;
    height: 12.802vw;
  }h5.title-tab {
    font-size: 2.415vw;
    line-height: 2.415vw;
  }.icon-tab-t {
    display: none;
  }p.result-count {
    font-size: 3.623vw;
    padding: 0 7.246vw;
    margin-bottom: 6.28vw;
  }.tab-titles {
    margin-bottom: 6.28vw;
  }.result-search-cont {
    padding: 1.719vw 0;
    margin: 0 7.729vw;
    background-image: none;
  }img.res-s-img {
    width: 20.048vw;
    height: 25.604vw; 
  }h5.type-result {
    font-size: 2.174vw;
    line-height: 2.174vw;
    margin-bottom: 3.623vw;
  }h5.res-title {
    font-size: 3.382vw;
    line-height: 3.382vw;
  }p.parrafo-result {
    width: auto;
    font-size: 2.415vw;
  }#no-avalibleN {
    display: flex;
    justify-content: center;
}.en-construccion-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 45vw;
    width: 50vw;
    margin: 0 auto;
}
}
</style>