<template>
  <transition name="modal-fade">
  <div class="modal2-backdrop" @click="close">
    <div class="modal2" @click.stop="">
      <header class="modal2-header">
        <h1 class="name-header-modal">
        <slot name="header">
          Messi!
        </slot>
        </h1>
        <button
          type="button"
          class="btn-close"
          @click="close"
        >
          x
        </button>
      </header>

      <section class="modal2-body">
        <slot name="body">
          Wos!
        </slot>
       </section>

  <!--    <footer class="modal2-footer">
        <slot name="footer">
        </slot>
      </footer>-->
    </div>
  </div>
  </transition>
</template>


<script>
  export default {
    name: 'Modal',
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>